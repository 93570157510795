import request from '@/utils/request'

export function itemCate(params) {
	return request({
		url: '/item/cate/list',
		method: 'post',
		data: params
	})
}


export function itemList(params) {
	return request({
		url: '/item/item/list',
		method: 'post',
		data: params
	})
}


export function itemDetail(params) {
	return request({
		url: '/item/item/info',
		method: 'post',
		params
	})
}

export function itemCode(params) {
	return request({
		url: '/item/code',
		method: 'post',
		params
	})
}