<template>
	<div>
		<Header></Header>
		<div class="common-header">
			<div class="search-healder">
				<div class="logo-box">
					<img @click="toUrl('/')" class="logo" src="../../image/index/logo.png" />
				</div>
				<div class="search">
					<input placeholder="请输入商品名称" v-model="params.data.name" />
					<div class="search-btn el-icon-search" @click="search()"> </div>
				</div>
			</div>
		</div>
		<div class="search-content">
			<div class="search-box">
				<div class="cate">
					<span class="title">分类:</span>
					<span class="name" :class="index==parentIndex?'name-active':''" v-for="(cate,index) in cates"
						:key="index" @click="selectParent(index)">{{cate.name}}</span>
				</div>
				<div class="cate child" v-if="parentIndex!=null">
					<span class="title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
					<span class="name" :class="index==cateIndex?'name-active':''"
						v-for="(cate,index) in cates[parentIndex].children" :key="index"
						@click="selectCate(index)">{{cate.name}}</span>
				</div>
				<div class="tools">
					<div class="tool-item" :class="sortType==0?'tool-active':''" @click="sort('',0)">综合排序</div>
					<div class="tool-item" :class="sortType==1?'tool-active':''" @click="sort('sell',1)">销量</div>
					<div class="tool-item" :class="sortType==2?'tool-active':''" @click="sort('price',2)">价格</div>
				</div>
			</div>
			<ul class="item-nav">
				<li class="item-list" v-for="(item,index) in list" :key="index"
					@click="toUrl('/item/detail?id='+item.id)">
					<div class="item-img">
						<img src="../../assets/drug.jpg" v-if="item.itemType==5" :alt="item.name" />
						<img :src="item.adPic" v-if="item.itemType!=5" :alt="item.name" />
					</div>
					<div class="item-name">
						<span v-if="item.itemType==5">处方药</span>{{item.name}}
					</div>
					<div class="item-price">
						<div class="mod-price">
							<i>￥</i>
							<span>{{item.isPrice}}</span>
							<span class="io-price" v-if="item.ioPrice>item.isPrice">￥{{item.ioPrice}}</span>
						</div>
					</div>
					<div class="shop-name">
						<p>{{item.shopName}}</p>
					</div>
				</li>
				<div class="empty" v-if="list.length==0">
					未搜索到相关商品
				</div>
				<div class="page">
					<el-pagination :hide-on-single-page="true" v-if="list.length>0" :current-page="params.page"
						:page-size="params.pageSize" layout="prev, pager, next" :total="total"
						@current-change="currentChange" />
				</div>
			</ul>
		</div>
		<Footer></Footer>

	</div>
</template>

<script>
	import {
		getCookie,
		delCookie
	} from '@/utils/auth'
	import {
		itemCate,
		itemList
	} from '@/api/item/item'
	export default {
		data() {
			return {
				cates: [],
				cateIndex: null,
				params: {
					data: {
						isSell: 1,
						cateIds: [],
						itemTypes: [5, 6],
						name: ''
					},
					page: 1,
					pageSize: 12
				},
				list: [],
				total: 0,
				more: true,
				loading: false,
				parentIndex: null,
				sortType: 0
			};
		},
		created() {
			if (this.$route.query.name) {
				this.params.data.name = this.$route.query.name
			}
			if (this.$route.query.cate) {
				this.params.data.cateIds = [this.$route.query.cate]
			}
			this.getCate()
			this.getItemList()
		},
		methods: {
			toUrl(url) {
				this.$router.push({
					path: url
				})
			},
			search() {
				this.params.page = 1
				this.list = []
				this.more = true
				this.getItemList()
			},
			sizeChange(val) {
				this.params.pageSize = val
				this.getItemList()
			},
			currentChange(val) {
				this.params.page = val
				this.getItemList()
			},
			getCate() {
				itemCate({}).then(res => {
					this.cates = res.data
					if (this.$route.query.type && this.$route.query.cate) {
						if (this.$route.query.type == 2) {
							for (let index in this.cates) {
								if (this.$route.query.cate == this.cates[index].id) {
									this.parentIndex = index
									return
								}
							}
						} else {
							this.cateId = this.$route.query.cate
							for (let index in this.cates) {
								for (let i in this.cates[index].children) {
									if (this.$route.query.cate == this.cates[index].children[i].id) {
										this.parentIndex = index
										this.cateIndex = i
										return
									}
								}
							}
						}
					}

				})
			},
			handleMouseEnter(e) {
				var index = e.target.dataset.id
				this.cateIndex = index
				this.cateShow = true
			},
			handleMouseLeave(e) {
				this.cateShow = false
			},
			getItemList() {
				if (this.loading) {
					return
				}
				if (!this.more) {
					return
				}
				this.loading = true
				itemList(this.params).then(res => {
					this.list = res.data.data
					this.total = res.data.total
					this.loading = false
				})
			},
			selectParent(index) {
				if (this.parentIndex != index) {
					this.parentIndex = index
					this.cateIndex = null
					this.params.data.cateIds = [this.cates[this.parentIndex].id]
					this.params.page = 1
					this.getItemList()
				}
			},
			selectCate(index) {
				if (index != this.cateIndex) {
					this.cateIndex = index
					this.params.data.cateIds = [this.cates[this.parentIndex].children[this.cateIndex].id]
					this.params.page = 1
					this.getItemList()
				}
			},
			sort(type, sort) {
				this.sortType = sort
				if (!type) {
					this.params.data.orderCol = ''
					this.params.data.isAsc = ''
					this.params.data.isNew = null
				} else if (type == 'sell') {
					this.params.data.orderCol = type
					if (this.params.data.isAsc) {
						this.params.data.isAsc = this.params.data.isAsc == 'asc' ? 'desc' : 'asc'
					} else {
						this.params.data.isAsc = 'desc'
					}
					this.params.data.isNew = null
				} else if (type == 'price') {
					this.params.data.orderCol = type
					if (this.params.data.isAsc) {
						this.params.data.isAsc = this.params.data.isAsc == 'asc' ? 'desc' : 'asc'
					} else {
						this.params.data.isAsc = 'asc'
					}
				}
				this.params.page = 1
				this.getItemList()
			}
		},
	};
</script>

<style lang='scss'>
	.search-content {
		min-width: 1200px;

		.search-box {
			width: 80%;
			margin-left: 10%;
			min-height: 100px;

			.cate {
				line-height: 2;
				font-size: 16px;

				.title {
					padding: 0 20px 0 0;
				}

				.name {
					padding: 0 10px;
					cursor: pointer;
				}

				.name:hover {
					color: #e3393c;
				}

				.name-active {
					color: #e3393c;
				}
			}

			.child {
				font-size: 14px;
			}

			.tools {
				width: 100%;
				display: flex;
				margin-top: 20px;

				.tool-item {
					line-height: 40px;
					padding-left: 10px;
					padding-right: 10px;
					font-size: 16px;
					cursor: pointer;
				}

				.tool-item:hover {
					background: rgb(246, 246, 246);
					font-weight: bold;
					border-radius: 12px 12px 0px 0px;
					color: #e3393c;
				}

				.tool-active {
					background: rgb(246, 246, 246);
					font-weight: bold;
					border-radius: 12px 12px 0px 0px;
					color: #e3393c;
				}
			}
		}

		.item-nav {
			margin-left: 10%;
			width: 80%;
			background-color: #f4f4f4;

			.item-list {
				cursor: pointer;
				background: #fff;
				display: inline-table;
				width: 230px;
				height: 322px;
				margin: 0 10px 10px;
				margin-top: 20px;
				border-radius: 2px;

				.item-img {
					display: block;
					transition: background .2s linear, opacity .2s linear !important;
					background: transparent;
					position: relative;
					overflow: hidden;
					width: 150px;
					height: 150px;
					margin: 30px auto 40px;
				}

				.item-img:hover {
					width: 160px;
					height: 160px;
					margin: 20px auto 40px;
				}

				.item-img>img {
					display: block;
					width: auto;
					height: auto;
					max-width: 100%;
					max-height: 100%;
					margin: 0 auto;
				}

				.item-name {
					padding: 0 20px;
					height: 48px;
					font-size: 14px;
					line-height: 24px;
					text-align: left;
					color: #333;
					-webkit-transition: color .2s ease;
					transition: color .2s ease;
					word-break: break-all;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;

					span {
						padding-left: 5px;
						padding-right: 5px;
						color: #fff;
						background-color: #e3393c;
						font-size: 12px;
						border-radius: 5px;
						margin-right: 5px;
					}
				}

				.item-name:hover {
					font-weight: bold;
				}

				.item-price {
					margin-top: 10px;
					padding-left: 20px;

					.mod-price {
						float: left;
						width: 75px;
						text-align: left;
						display: inline-block;
						font-size: 16px;
						line-height: 18px;
						height: 18px;
						color: #e1251b;

						i {
							vertical-align: middle;
							font-size: 12px;
							font-weight: 700;
						}

						span {
							font-size: 20px;
							font-weight: 700;
						}

						.io-price {
							font-size: 16px;
							color: #666;
							text-decoration: line-through;
							font-weight: normal;
							padding-left: 10px;
						}
					}
				}

				.shop-name {
					clear: both;
					padding-left: 20px;
					padding-top: 10px;
				}
			}

			.item-list:hover {
				transition: top .25s ease;
				box-shadow: 2px 2px 5px rgba(0, 0, 0, .3);
			}
		}
	}

	.empty {
		width: 100%;
		color: #777;
		height: 500px;
		text-align: center;
		font-size: 30px;
		line-height: 500px;
	}

	.page {
		margin-top: 20px;
		width: 100%;
		margin-bottom: 20px;

		.el-pagination {
			text-align: center;
		}

		button {
			background: none !important;
			font-size: 18px;

			i {
				font-size: 18px !important;
			}
		}

		.el-pager li {
			background: none;
			font-size: 18px;
			font-weight: normal !important;
		}
	}
</style>